var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          {
            class: [
              _vm.scenarioViewGroup === "L"
                ? "col-12"
                : "col-xs-12 col-sm-6 col-md-4 col-lg-4",
              "scenario-list",
            ],
          },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "LBL0001205",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.setheight,
                  data: _vm.process.charmEstimationScenarios,
                  merge:
                    _vm.scenarioViewGroup === "L" ||
                    _vm.scenarioViewGroup === "C"
                      ? _vm.grid.merge
                      : [],
                  filtering: false,
                  customTrClass: _vm.setTrClass,
                  columnSetting: false,
                  usePaging: false,
                  isExcelDown: _vm.scenarioViewGroup === "L",
                  isFullScreen: _vm.scenarioViewGroup === "L",
                  editable: _vm.editable && !_vm.disabled,
                },
                on: { "table-data-change": _vm.tableDataChange },
                scopedSlots: _vm._u([
                  {
                    key: "suffixTitle",
                    fn: function () {
                      return [
                        _c(
                          "q-btn-group",
                          {
                            staticClass: "scenario-view-group",
                            attrs: { outline: "" },
                          },
                          [
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  outline: _vm.scenarioViewGroup !== "L",
                                  color:
                                    _vm.scenarioViewGroup === "L"
                                      ? "indigo"
                                      : "grey",
                                  size: "11px",
                                  label: "",
                                  icon: "view_list",
                                },
                                on: { click: _vm.listView },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0001167")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  outline: _vm.scenarioViewGroup !== "C",
                                  color:
                                    _vm.scenarioViewGroup === "C"
                                      ? "indigo"
                                      : "grey",
                                  size: "11px",
                                  label: "",
                                  icon: "space_dashboard",
                                },
                                on: { click: _vm.cardView },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0001168")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "item"
                          ? [
                              _c(
                                "q-item",
                                { staticClass: "card-scenario-list" },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c(
                                        "q-item-label",
                                        { staticClass: "scenario-card-title" },
                                        [
                                          _vm._v(
                                            _vm._s(`${props.row["casNo"]} `)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    {
                                      staticStyle: { "padding-right": "0px" },
                                      attrs: { side: "" },
                                    },
                                    [
                                      _c("q-btn", {
                                        attrs: {
                                          unelevated: "",
                                          dense: "",
                                          color: "primary",
                                          label: "▶",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetail(
                                              props.row
                                                .ramCharmEstimationAssessScenarioId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-update-btn", {
                          attrs: {
                            name: "updateBtn",
                            data: _vm.estimationUpdateBtnData,
                            btnEditable: _vm.estimationBtnEditable,
                            flagCondition: _vm.flagCondition,
                          },
                          on: { back: _vm.back },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isCompared,
                              expression: "isCompared",
                            },
                          ],
                          attrs: {
                            label: "LBL0001166",
                            icon: "compare_arrows",
                          },
                          on: { btnClicked: _vm.compareScenario },
                        }),
                        _vm.editable &&
                        !_vm.disabled &&
                        _vm.scenarioViewGroup === "L"
                          ? _c("c-btn", {
                              attrs: {
                                isSubmit: _vm.isSave,
                                url: _vm.saveUrl,
                                param: _vm.process.charmEstimationScenarios,
                                mappingType: "PUT",
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveScenario,
                                btnCallback: _vm.saveScenarioCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm.scenarioViewGroup === "C"
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card",
              },
              [
                _c("q-form", { ref: "editForm" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            staticStyle: { height: "625px" },
                            attrs: { title: "LBL0001128" },
                          },
                          [
                            _c(
                              "template",
                              { slot: "card-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _c("c-btn", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.editable &&
                                            !_vm.disabled &&
                                            _vm.isCardSelect,
                                          expression:
                                            "editable&&!disabled&&isCardSelect",
                                        },
                                      ],
                                      attrs: {
                                        isSubmit: _vm.isSaveUnit,
                                        url: _vm.saveUnitUrl,
                                        param: _vm.scenario,
                                        mappingType: "PUT",
                                        label: "LBLSAVE",
                                        icon: "save",
                                      },
                                      on: {
                                        beforeAction: _vm.saveScenarioUnit,
                                        btnCallback:
                                          _vm.saveScenarioUnitCallback,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("p", { staticClass: "font" }, [
                                    _vm._v(_vm._s(_vm.$label("LBL0001206"))),
                                  ]),
                                  _c("c-checkbox", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      isFlag: true,
                                      label: "LBL0001129",
                                      name: "suspectUserFlag",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.cardDataChange(
                                          "suspectUserFlag"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.scenario.suspectUserFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "suspectUserFlag",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.suspectUserFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("p", { staticClass: "font2" }, [
                                      _vm._v(_vm._s(_vm.$label("LBL0001207"))),
                                    ]),
                                    _c("c-text", {
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: {
                                        disabled:
                                          _vm.disabled || !_vm.selectScenario,
                                        editable: _vm.editable,
                                        label: "LBL0001131",
                                        name: "measValue",
                                      },
                                      on: {
                                        dataChange: function ($event) {
                                          return _vm.cardDataChange("measValue")
                                        },
                                      },
                                      model: {
                                        value: _vm.scenario.measValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "measValue",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.measValue",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("c-text", {
                                      staticStyle: { "margin-top": "41px" },
                                      attrs: {
                                        disabled:
                                          _vm.disabled || !_vm.selectScenario,
                                        editable: _vm.editable,
                                        label: "LBL0001132",
                                        name: "exposureStandard",
                                      },
                                      on: {
                                        dataChange: function ($event) {
                                          return _vm.cardDataChange(
                                            "exposureStandard"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.scenario.exposureStandard,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "exposureStandard",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.exposureStandard",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("c-text", {
                                      staticStyle: { "margin-top": "41px" },
                                      attrs: {
                                        editable: false,
                                        label: "LBL0001133",
                                        name: "exposureMeasValue",
                                      },
                                      model: {
                                        value: _vm.scenario.exposureMeasValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "exposureMeasValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scenario.exposureMeasValue",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c("p", { staticClass: "font2" }, [
                                      _vm._v(_vm._s(_vm.$label("LBL0001208"))),
                                    ]),
                                    _c("c-text", {
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: {
                                        disabled:
                                          _vm.disabled || !_vm.selectScenario,
                                        editable: _vm.editable,
                                        label: "LBL0001135",
                                        name: "limitRepval",
                                      },
                                      on: {
                                        dataChange: function ($event) {
                                          return _vm.cardDataChange(
                                            "limitRepval"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.scenario.limitRepval,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "limitRepval",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.limitRepval",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c("c-text", {
                                      staticStyle: { "margin-top": "41px" },
                                      attrs: {
                                        editable: false,
                                        label: "LBL0001136",
                                        name: "dailyVolume",
                                      },
                                      model: {
                                        value: _vm.scenario.dailyVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "dailyVolume",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.dailyVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c("c-text", {
                                      staticStyle: { "margin-top": "41px" },
                                      attrs: {
                                        editable: false,
                                        label: "LBLUNIT",
                                        name: "volumeUnitName",
                                      },
                                      model: {
                                        value: _vm.scenario.volumeUnitName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "volumeUnitName",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.volumeUnitName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c("c-text", {
                                      staticStyle: { "margin-top": "41px" },
                                      attrs: {
                                        editable: false,
                                        label: "LBL0001137",
                                        name: "dailyLevel",
                                      },
                                      model: {
                                        value: _vm.scenario.dailyLevel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "dailyLevel",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.dailyLevel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-3" },
                                [
                                  _c("c-select", {
                                    attrs: {
                                      type: "edit",
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "LBL0001138",
                                      name: "maleficenceType",
                                      codeGroupCd: "MALEFICENCE_TYPE",
                                      itemText: "codeName",
                                      itemValue: "code",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.cardDataChange(
                                          "maleficenceType"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.scenario.maleficenceType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "maleficenceType",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.maleficenceType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isMaleficenceType1
                                ? _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c("c-select", {
                                        attrs: {
                                          type: "edit",
                                          disabled:
                                            _vm.disabled ||
                                            !_vm.selectScenario ||
                                            _vm.maleficenceTypeFlag,
                                          editable: _vm.editable,
                                          label: "LBL0001139",
                                          name: "maleficenceGrade",
                                          codeGroupCd: "MALEFICENCE_GRADE",
                                          itemText: "codeName",
                                          itemValue: "code",
                                        },
                                        model: {
                                          value: _vm.scenario.maleficenceGrade,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.scenario,
                                              "maleficenceGrade",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scenario.maleficenceGrade",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.scenario.maleficenceType !== null &&
                              !_vm.maleficenceTypeFlag
                                ? _c("div", { staticClass: "col-9" })
                                : _vm._e(),
                              _vm.isMaleficenceType2
                                ? _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c("c-text", {
                                        attrs: {
                                          disabled:
                                            _vm.disabled ||
                                            !_vm.selectScenario ||
                                            !_vm.maleficenceTypeFlag,
                                          editable: _vm.editable,
                                          label: "LBL0001140",
                                          name: "boilingPoint",
                                        },
                                        on: {
                                          dataChange: function ($event) {
                                            return _vm.cardDataChange(
                                              "boilingPoint"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.scenario.boilingPoint,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.scenario,
                                              "boilingPoint",
                                              $$v
                                            )
                                          },
                                          expression: "scenario.boilingPoint",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isMaleficenceType2
                                ? _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c("c-text", {
                                        attrs: {
                                          editable: false,
                                          label: "LBL0001141",
                                          name: "volatilityGradeName",
                                        },
                                        model: {
                                          value:
                                            _vm.scenario.volatilityGradeName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.scenario,
                                              "volatilityGradeName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scenario.volatilityGradeName",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.scenario.maleficenceType === null
                                ? _c("div", { staticClass: "col-9" })
                                : _vm._e(),
                              _c("div", { staticClass: "row" }, [
                                _c("p", { staticClass: "font2" }, [
                                  _vm._v(_vm._s(_vm.$label("LBL0001209"))),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c("c-text", {
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: {
                                        editable: false,
                                        label: "LBL0001142",
                                        name: "exposureLevelCombination",
                                      },
                                      model: {
                                        value:
                                          _vm.scenario.exposureLevelCombination,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "exposureLevelCombination",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scenario.exposureLevelCombination",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("c-select", {
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: {
                                        disabled:
                                          _vm.disabled || !_vm.selectScenario,
                                        editable: _vm.editable,
                                        label: "LBL0001143",
                                        name: "closedVentilation",
                                        codeGroupCd: "CLOSED_VENTILATION",
                                        itemText: "codeName",
                                        itemValue: "code",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.cardDataChange(
                                            "closedVentilation"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.scenario.closedVentilation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "closedVentilation",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scenario.closedVentilation",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            attrs: { title: "LBL0001144" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("p", { staticClass: "font3" }, [
                                    _vm._v(_vm._s(_vm.$label("LBL0001206"))),
                                  ]),
                                  _c("c-checkbox", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      isFlag: true,
                                      label: "LBL0001152",
                                      name: "cmrFlag",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.cardDataChange("cmrFlag")
                                      },
                                    },
                                    model: {
                                      value: _vm.scenario.cmrFlag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.scenario, "cmrFlag", $$v)
                                      },
                                      expression: "scenario.cmrFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-8" }),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("p", { staticClass: "font4" }, [
                                    _vm._v(_vm._s(_vm.$label("LBL0001210"))),
                                  ]),
                                  _c("c-select", {
                                    staticStyle: { "margin-top": "15px" },
                                    attrs: {
                                      type: "edit",
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "LBL0001138",
                                      name: "maleficenceForm",
                                      codeGroupCd: "MALEFICENCE_FORM",
                                      itemText: "codeName",
                                      itemValue: "code",
                                    },
                                    model: {
                                      value: _vm.scenario.maleficenceForm,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "maleficenceForm",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.maleficenceForm",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-text", {
                                    staticStyle: { "margin-top": "35px" },
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "LBL0001146",
                                      name: "maleficenceValue",
                                    },
                                    on: {
                                      dataChange: function ($event) {
                                        return _vm.cardDataChange(
                                          "maleficenceValue"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.scenario.maleficenceValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "maleficenceValue",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.maleficenceValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("p", { staticClass: "font4" }, [
                                    _vm._v(_vm._s(_vm.$label("LBL0001211"))),
                                  ]),
                                  _c("c-select", {
                                    staticStyle: { "margin-top": "15px" },
                                    attrs: {
                                      type: "edit",
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "LBL0001147",
                                      name: "hrCodeGrade",
                                      codeGroupCd: "HR_CODE_GRADE",
                                      itemText: "codeName",
                                      itemValue: "code",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.cardDataChange("hrCodeGrade")
                                      },
                                    },
                                    model: {
                                      value: _vm.scenario.hrCodeGrade,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "hrCodeGrade",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.hrCodeGrade",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            attrs: {
                              title: "LBL0001212",
                              topClass: "topcolor-orange",
                            },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "q-list",
                                    {
                                      attrs: {
                                        dense: "",
                                        bordered: "",
                                        separator: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "q-item",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-risk-info-charm detail-risk",
                                            },
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$label("LBL0001212")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.scenario
                                                          .exposureLevelFinal
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("q-separator", {
                                            attrs: { spaced: "", vertical: "" },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-risk-info-charm detail-risk",
                                            },
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$label("LBL0001127")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(_vm.scenario.toxic)
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item",
                                        { staticClass: "detail-risk" },
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$label("LBL0001125")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-section",
                                            {
                                              staticClass:
                                                "detail-risk-num-charm",
                                              attrs: { side: "" },
                                            },
                                            [
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.scenario.riskEstimation
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                  _c("br"),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c("c-win-pop", {
          ref: "winPopup",
          on: {
            onClose: (val) => _vm.evtCloseWinPopup(val),
            onRecvEvtFromWinPop: (val) => _vm.onRecvWinPop(val),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }